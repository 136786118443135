<template>
  <v-container fluid class="down-top-padding">
    <div id="subjectsPage" class="page">
      <div class="page__header">
        <Toolbar
          :showFiltersPanel="showFiltersPanel"
          @showFiltersPanelChanged="handleShowFiltersPanelChanged"
          @toggleDialog="toggleDialog"
        />
      </div>
      <div class="page__body pa-4">
        <div class="mb-4" v-show="showFiltersPanel">
          <v-row class="my-0">
            <v-col>
              <Filters @filtersChanged="handleFiltersChanged" />
            </v-col>
          </v-row>
        </div>

        <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
          <Form
            v-if="dialog"
            @formSubmit="handleFormSubmit"
            :toggleDialog="toggleDialog"
            :isSubmittingForm="isSubmittingForm"
            :selectedSubject="selectedSubject"
            :subjectTypes="subjectTypes"
          />
        </v-dialog>
        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @editItem="handleEditItem"
          @deleteItem="handleDeleteItem"
          @changeStatus="handleChangeStatus"
          @optionsChanged="handleOptionsChanged"
        />
      </div>
      <ConfirmationPopup
        id="subject-delete-modal"
        v-if="subjectToDelete"
        title="Tədris fənnin silinməsi"
        :dialog="confirmationDialog"
        :loading="isDeletingSubject"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
      >
        <span class="font-weight-bold">
          {{ subjectToDelete.name }}
        </span>
        tədris fənnini silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import bus from "../../../event-bus";
import List from "./components/List/index.vue";
import Form from "./components/Form/index.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Toolbar/components/Filters.vue";

export default {
  components: {
    List,
    Form,
    Toolbar,
    ConfirmationPopup,
    Filters,
  },
  data() {
    return {
      dialog: false,
      confirmationDialog: false,
      selectedSubject: null,
      subjectToDelete: null,
      page: 1,
      isFetchingItems: false,
      isSubmittingForm: false,
      isDeletingSubject: false,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      subjectTypes: [],
      filters: {},
      sortedBy: null,
      sortedDesc: null,
      showFiltersPanel: false,
    };
  },
  async created() {
    this.fetchSubjects();
    this.subjectTypes = await API.fetchAllSubjectTypes();
  },
  methods: {
    fetchSubjects() {
      this.isFetchingItems = true;
      API.fetchSubjects({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        sortedBy: this.sortedBy,
        sortedDesc: this.sortedDesc,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Tədris fənnlərin siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleFormSubmit(formData) {
      this.isSubmittingForm = true;
      const request = this.selectedSubject
        ? API.updateSubject(this.selectedSubject.id, formData)
        : API.insertSubject(formData);

      request
        .then(() => {
          this.toggleDialog();
          this.fetchSubjects();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatı aparmaq mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    handleEditItem(item) {
      this.isFetchingItems = true;
      API.fetchSubjectById(item.id)
        .then((response) => {
          this.selectedSubject = response;
          this.dialog = true;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məlumatı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleDeleteItem(item) {
      this.subjectToDelete = item;
      this.confirmationDialog = true;
    },
    handleDeleteConfirmation() {
      this.isDeletingSubject = true;
      API.deleteSubject(this.subjectToDelete.id)
        .then(() => {
          this.confirmationDialog = false;
          this.subjectToDelete = null;
          this.fetchSubjects();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Tədris fənni silmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isDeletingSubject = false;
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.subjectToDelete = null;
    },
    handleFiltersChanged(filters) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.page = 1;
        this.filters = { ...filters };
        this.fetchSubjects();
      }
    },
    handleChangeStatus(item) {
      this.isFetchingItems = true;

      API.changeSubjectStatus(item.id)
        .then(() => {
          this.fetchSubjects();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Tədris fənnin statusunu dəyişmək mümkün olmadı.",
          });
        });
    },
    toggleDialog() {
      this.dialog = !this.dialog;
      if (!this.dialog) {
        this.selectedSubject = null;
      }
    },
    handleOptionsChanged(item) {
      if (
        this.sortedBy !== item.sortedBy ||
        this.sortedDesc !== item.sortedDesc ||
        this.page !== item.page ||
        this.itemsPerPage !== item.itemsPerPage
      ) {
        this.page = item.page;
        this.itemsPerPage = item.itemsPerPage;
        this.sortedBy = item.sortedBy;
        this.sortedDesc = item.sortedDesc;
        this.fetchSubjects();
      }
    },
    handleShowFiltersPanelChanged(item) {
      this.showFiltersPanel = item;
    },
  },
  
};
</script>
