<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "Tədris fənnin redaktəsi" : "Yeni tədris fənni" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createSubjectForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="text"
              label="Adı"
              filled
              dense
              v-model="formData.name"
              :rules="formRules.name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Qiyməti"
              filled
              dense
              v-model="formData.price"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="number"
              label="Endirim Faizi"
              filled
              dense
              value="0"
              v-model="formData.discountPercent"
            ></v-text-field>
          </v-col>

           <v-col cols="6" class="">
            <v-autocomplete
              label="Fənn Qrupu"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.subjectTypeId"
              :items="subjectTypes"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-textarea
              type="text"
              label="Təsviri"
              filled
              dense
              auto-grow
              rows="1"
              v-model="formData.description"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createSubjectForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Yadda saxla" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedSubject: {
      type: Object,
      required: false,
      default: () => null,
    },    
    subjectTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        name: "",
        description: "",
        price:"",
        discountPercent:0,
        subjectTypeId:null

      },
      formRules: {
        name: [(v) => !!v || "Tədris fənnin adını daxil edin"],
      },
    };
  },
  computed: {
    isEditMode() {
      return (
        this.selectedSubject && this.selectedSubject.id && this.selectedSubject.id > 0
      );
    },
  },
  watch: {
    selectedSubject: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        name: "",
        description: "",
        price:"",
        discountPercent:0,
        subjectTypeId:null
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
  },
};
</script>
